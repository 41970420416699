.usersPageCont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    /* padding-bottom: 2rem; */
    overflow-y: scroll;
    background-color:#F8F9FB;
}

.headingCont{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    gap:2rem;
    justify-content: space-between;
}

.headingContLeft{
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* background-color: yellow; */
}

.headingCont span:nth-child(1){
    color: #364153;
    font-family: Raleway;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.card{
    height: 14.5rem;
    max-width: 32rem;
    border-radius: 8px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.cardTop{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: aqua; */
}
.cardTopLeft{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
}

.cardTopLeft span:nth-child(1){
    color: #364153;
    font-family: Inter;
    font-size: 3.1rem;
    font-style: normal;
    font-weight: 600;
    white-space: nowrap;
}

.cardTopLeft span:nth-child(2){
    color: #364153;
    font-family: Raleway;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    white-space: nowrap;
}

.cardTop img{
    width: 4rem;
    height: 4rem;
    /* object-fit: cover; */
}

.cardBottom{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.cardBottomSub{
    height: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    border-radius: 7.926px;
    background: #F8F9FB;
}

.cardBottomSub span:nth-child(1){
    color: #FD5942;
    font-family: Inter;
    font-size: 12.681px;
    font-style: normal;
    font-weight: 600;
    padding-left: 2rem;
    white-space: nowrap;
}


.cardBottomSub span:nth-child(2){
    color: #6B6B6B;
    font-family: Inter;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
}

.searchnFiltCont{
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap:1rem
}

.searchContainer {
    display: flex;
    align-items: center;
  }

  .searchBox {
    position: relative;
  }

  .searchBox input[type="text"] {
    padding: 10px 30px 10px 40px; 
    border: none;
    border-radius: 7px;
    outline: none;
    width: 32rem; /* Set the desired width */
    color: #747474;
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
  }

  .searchBox img {
    position: absolute;
    left: 10px; 
    top: 50%;
    transform: translateY(-50%);
    width: 20px; 
    height: 20px; 
  }

  .filterContainer {
    display: flex;
    align-items: center;
  }

  .filterBox {
    position: relative;
  }

  .filterBox input[type="text"] {
    padding: 10px 30px 10px 40px; 
    border: none;
    border-radius: 7px;
    outline: none;
    width: 22rem; /* Set the desired width */
    color: #747474;
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
  }

  .filterBox img {
    position: absolute;
    left: 10px; 
    top: 50%;
    transform: translateY(-50%);
    width: 20px; 
    height: 20px; 
  }

.bottomCont{
    width: 100%;
    height: fit-content;
    min-height: 20rem;
    overflow-y: scroll;
}

.bottomContTable{
    width: 100%;
    height: fit-content;
    overflow-y: scroll;
}

.imageHeader {
  width: 100%;
  display: flex;
  padding-right: 50px;
  justify-content: flex-end;
  align-items: center;
}

.optionsCell {
  width: 100%;
  display: flex;
  padding-right: 50px;
  justify-content: flex-end;
  align-items: center;
}

.optionsMenuCont {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
}

.resetBtn {
  padding: 0.2rem;
  cursor: pointer;
  font-weight: 600;
  background-color:transparent;
  color: #36B14D;
  border: none;
  margin-right: 3rem;
}

.optionsMenuBtn {
  width: 5rem;
  padding: 0.2rem;
  font-weight: 600;
  cursor: pointer;
  background-color: #fff;
  color: #36B14D;
  border: none;
  border-radius: 3px;
}

.optionsMenu {
  position: absolute;
  top: 100%;
  left: 57%;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px;
  z-index: 1;
}

.menuItem {
  cursor: pointer;
  padding: 5px;
  &:hover {
    background-color: #f5f5f5;
  }
}

/* Add these styles to your existing CSS file or create a new one */

.paginationContainer {
  display: flex;
  justify-content: end;
  margin-top: 10px;
  align-items: center;
}

.currentPageCont{
  border: 1px solid #000;
  height: fit-content;
  min-height: 2.8rem;
  width: fit-content;
  min-width: 2rem;
  padding:0px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.currentPageCont span{
  font-size: 1.4rem !important;
  font-weight: 600 !important;
}

.paginationContainer button {
  width: 7rem;
  background-color: #4caf50;
  border: none;
  color: white;
  padding:5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
}

.paginationContainer button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

@media only screen and (max-width: 903px) {
  .bottomContTable{
    width: 85rem;
    height: fit-content;
    overflow-y: scroll;
}
}


@media only screen and (max-width: 767px) {
  .bottomCont{
    width: 100%;
    height: fit-content;
    overflow-y: scroll;
    overflow-x: scroll;
}
  .bottomContTable{
    width: 85rem;
    height: fit-content;
    overflow-y: scroll;
}
}

@media only screen and (max-width: 724px) {
    .searchBox input[type="text"] {
        padding: 10px 30px 10px 40px; 
        border: none;
        border-radius: 7px;
        outline: none;
        width: 22rem; /* Set the desired width */
        color: #747474;
        font-family: Inter;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
      }
}

@media only screen and (max-width: 628px) {
    .searchBox input[type="text"] {
        padding: 10px 30px 10px 40px; 
        border: none;
        border-radius: 7px;
        outline: none;
        width: 18rem;
        color: #747474;
        font-family: Inter;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
      }
      .filterBox input[type="text"] {
        padding: 10px 30px 10px 40px; 
        border: none;
        border-radius: 7px;
        outline: none;
        width: 18rem;
        color: #747474;
        font-family: Inter;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
      }
}

@media only screen and (max-width: 551px) {
    .searchBox input[type="text"] {
        padding: 10px 30px 10px 40px; 
        border: none;
        border-radius: 7px;
        outline: none;
        width: 15.5rem;
        color: #747474;
        font-family: Inter;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
      }
      .filterBox input[type="text"] {
        padding: 10px 30px 10px 40px; 
        border: none;
        border-radius: 7px;
        outline: none;
        width: 15.5rem;
        color: #747474;
        font-family: Inter;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
      }
}

@media only screen and (max-width: 500px) {
    .usersPageCont{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap:1.5rem;
        /* padding-bottom: 2rem; */
        overflow-y: scroll;
        background-color: #F8F9FB;
    }
     
    .headingContLeft{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        /* background-color: yellow; */
    }

    .card{
        height: 14.5rem;
        max-width:100%;
        border-radius: 8px;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        background-color: #fff;
    }
}


@media only screen and (max-width: 362px) {
    .searchnFiltCont{
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap:1rem
    }

    .searchContainer {
        display: flex;
        align-items: center;
        width: 100%;
      }
    
      .searchBox {
        width: 100%;
        position: relative;
      }
    
      .searchBox input[type="text"] {
        padding: 10px 30px 10px 40px; 
        border: none;
        border-radius: 7px;
        outline: none;
        width: 100%;
        color: #747474;
        font-family: Inter;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
      }
    
      .filterContainer {
        width: 100%;
        display: flex;
        align-items: center;
      }
    
      .filterBox {
        position: relative;
        width: 100%;
      }
    
      .filterBox input[type="text"] {
        padding: 10px 30px 10px 40px; 
        border: none;
        border-radius: 7px;
        outline: none;
        width: 100%;
        color: #747474;
        font-family: Inter;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
      }
}

@media only screen and (max-width: 280px) {
    .cardBottomSub span:nth-child(1){
        color: #FD5942;
        font-family: Inter;
        font-size: 12.681px;
        font-style: normal;
        font-weight: 600;
        padding-left: 0;
        white-space: nowrap;
    }   
    .cardTop{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap:0.5rem;
        /* background-color: aqua; */
    }
    .cardTopLeft span:nth-child(2){
        color: #364153;
        font-family: Raleway;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 600;
        white-space: pre-wrap;
    }
}