.adminPageContainer{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #F8F9FB;
}

.headerContainer{
    max-width: 100%;/*sri*/
    height: 5rem;
    background: linear-gradient(271deg, #ECF6DE 70.82%, rgba(255, 255, 255, 0.00) 100.48%);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding:0 2rem;
    }
    
    .headerContainer img{
        /* width:10rem; */
        height: 3.5rem;
    }
    
    .user{
        width:2.5rem !important;
        height: 2.5rem !important;
        cursor: pointer; 
    }

    .headerBtnCont{
        display: flex;
        flex-direction: row;
        gap:1rem;
    }
    
    .headerBtnCont button:first-child{
        width: 6rem;
        height: 2.8rem;
        /* background-color:#fff; */
        border: none ;
        color: #000;
        font-family: Raleway;
        font-size: 11px;
        font-weight: 600;
        cursor: pointer;
    }

    .bottomCont{
        width: 100%;
        height: calc(100vh - 5rem);
        display: flex;
        flex-direction: row;
        /* background-color: blanchedalmond; */
    }

    .sideBarCont{
        width: 14rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color:#fff;
    }

    .sideBarCont div{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.5rem;
        height: 4.5rem;
    }

    .sideBarCont div img{
        width: 12px;
        height: 12px;
    }

    .sideBarCont div span{
        color: #364153;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 55.479px;
    }

    .rightCont{
        width: calc(100% - 14rem);
        height: 100%;
        padding: 1.5rem;
        padding-top: 0;
        /* padding-bottom: 0; */
        overflow: scroll;
        background-color:#F8F9FB;
    }

    .logoutPopup {
        position: absolute;
        right: 2%;
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 2px;
        margin-top: 4rem;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
        width: 10rem;
        border-radius: 5px;
        cursor: pointer;
      }
      
      .logoutPopup p {
        margin: 0;
        padding: 5px;
        cursor: pointer;
        color: #000;
        font-size: 1.2rem;
      }
      
      .logoutPopup p:hover {
        color: red;
      }

    @media only screen and (max-width: 500px) {
        .headerContainer{
            max-width: 144rem;
            height: 4rem;
            /* background-color: #fff; */
            display:flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding:0 1rem;
            }
            .headerContainer img{
                /* width:10rem; */
                height: 3.5rem;
            }
            .user{
                width:2rem !important;
                height: 2rem !important;
                cursor: pointer; 
            }
            .bottomCont{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                /* background-color: blanchedalmond; */
            }
            .sideBarCont{
                width: 100%;
                height: fit-content;
                display: flex;
                flex-direction: row;
                background-color:#fff;
            }
            .rightCont{
                width: 100%;
                height: 100%;
                padding: 1.5rem;
                overflow: hidden;
                background-color:#F8F9FB;
            }
        }

        @media only screen and (max-width: 440px) {
            .sideBarCont div{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 1.5rem;
                height: 4.5rem;
            }
        }