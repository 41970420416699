.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    z-index: 10;
    background-color: rgba(0,0,0,0.5); /* semi-transparent background */
  }
  
  /* Modal content styles */
  .modalContent {
    width: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap:1.5rem
  }

  .modalHeader{
    width: 100%;
    height: fit-content;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:3rem;
  }

  .heading{
    color: #b8b8b8 !important;
    font-family: Inter;
    font-size: 2rem !important;
    font-style: normal;
    font-weight: 600 !important;
  }

  .date{
    margin-top: 0.3rem;
    font-size: 1.3rem !important;
    font-weight: 500 !important;
  }
  
  /* Close button styles */
  .close {
    color: #aaa !important;
    margin-left: auto;
    font-size: 28px !important;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .modalContentSub{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap:2rem;
    flex-wrap: wrap;
  }

  .bottomCont{
    width: 100%;
    height: fit-content;
    min-height: 20rem;
    overflow-y: scroll;
}

.bottomContTable{
    width: 100%;
    height: fit-content;
    overflow-y: scroll;
}

.modalBottomCont{
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content:flex-end
}

.paginationContainer {
  display: flex;
  justify-content: end;
  /* margin-top: 10px; */
  align-items: center;
}

.currentPageCont{
  border: 1px solid #000;
  height: fit-content;
  min-height: 2.8rem;
  width: fit-content;
  min-width: 2rem;
  padding:0px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.currentPageCont span{
  font-size: 1.4rem !important;
  font-weight: 600 !important;
}

.paginationContainer button {
  width: 7rem;
  background-color: #4caf50;
  border: none;
  color: white;
  padding:5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
}

.paginationContainer button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

@media only screen and (max-width: 670px) { 
  .bottomCont{
    width: 100%;
    height: fit-content;
    min-height: 20rem;
    overflow-y: scroll;
}

.bottomContTable{
    width: 80rem;
    height: fit-content;
    overflow-y: scroll;
}
}

@media only screen and (max-width: 422px) { 
  .heading{
    color: #b8b8b8 !important;
    font-family: Inter;
    font-size: 1.8rem !important;
    font-style: normal;
    font-weight: 600 !important;
  }

.date{
  margin-top: 0.3rem;
  font-size: 1rem !important;
  font-weight: 500 !important;
}
}