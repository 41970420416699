.mainContainer {
    height: 100vh;
    max-width: 100%;/*sri*/
    margin: 0 auto;
    /* background-image: url("https://png.pngtree.com/thumb_back/fh260/back_our/20190622/ourmid/pngtree-network-information-electronic-technology-background-image_209089.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    background-color: #fff;
  }

  .outlinedBtn{
    width: 16rem;
    height: 5.2rem;
    background-color: transparent;
    border: 1.5px solid #36B14D ;
    border-radius: 3px;
    color: #36B14D;
    font-family: Raleway;
    font-size: 2.0rem;
    font-weight: 600;
    cursor: pointer;
  }

  .outlinedBtnBottom{
    width: 8rem;
    height: 3.2rem;
    background-color: transparent;
    border: 1.5px solid #36B14D ;
    border-radius: 3px;
    color: #36B14D;
    font-family: Raleway;
    font-size: 1.7rem;
    font-weight: 600;
    cursor: pointer;
  }

  @media only screen and (max-width: 500px) {
    .mainContainer {
      height: 100%;
      max-width: 100%;/*sri*/
      margin: 0 auto;
      background-color: #fff;
    }
  }