.homePageContainer{
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color:#fff;
}

.homePageContainerSub{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.headerContainer{
max-width: 100%;/*sri*/
height: 6rem;
background-color: #fff;
display:flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
padding:0 2rem;
}

.headerContainer img{
    /* width:10rem; */
    height: 4rem;
}

.user{
    width:2.5rem !important;
    height: 2.5rem !important;
    cursor: pointer; 
}

.mail{
    width:2.5rem !important;
    height: 2.5rem !important;
    cursor: pointer; 
}

.headerBtnCont{
    position: relative;
    display: flex;
    flex-direction: row;
    gap:2rem;
}

.headerBtnCont button:first-child{
    width: 6rem;
    height: 2.8rem;
    /* background-color:#fff; */
    border: none ;
    color: #000;
    font-family: Raleway;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}

.imgContainer{
max-width: 100%;/*sri*/
min-height: 8rem;
max-height: 14rem;
overflow: hidden;
position: relative;
display: flex;
justify-content: center;
align-items: center;
}

.imgContainer img {
    width: 100%;
    height: 100%; 
    object-fit: cover;
}

.imgContainer span {
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    color: #000;
    font-family: Raleway;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 70px; 
}

.bottomContainer{
    max-width: 100%;/*sri*/
    min-height: 35rem;
    padding:1rem 2rem;
    /* background-color: yellow;   */
    overflow-y: scroll; 
}

.bottomContainerSub{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap:1rem;
    /* background-color: blueviolet; */
}

.bottomContainerSub button{
    width: 15rem;
    height: 3rem;
    background-color: #36B14D;
    border: 1px solid #36B14D ;
    border-radius: 3px;
    color: #FFF;
    font-family: Raleway;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
}

.bottomContainer button:hover{
    background-color:#42dc5e;
    border: 1px solid #42dc5e;
}


.bottomContBtn{
    width: 100%;
    /* background-color: red; */
}

.bottomContTableQuot{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap:2rem;
    /* background-color: blue; */
    overflow-y: scroll;
}

.bottomContTable{
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.tableDiv{
    height: 88%;
    width: 100%;
    overflow-y: scroll;
}

.quoteImgCont{
    width: 30%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    /* background-color: #36B14D; */
}

.quoteImgCont img{
    height: 92%;
    width: 100%;
}

.bottomOptions{
    width: 4rem;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-self: self-end;
    margin-right: 0.5rem;
    /* background-color: red; */
}

.bottomOptions img{
    width: 13px !important;
    height: 13px !important;
    cursor: pointer;
}

.logoutPopup {
    position: absolute;
    right: 2%;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 2px;
    margin-top: 4rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    width: 10rem;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .logoutPopup p {
    margin: 0;
    padding: 5px;
    cursor: pointer;
    color: #000;
    font-size: 1.2rem;
  }
  
  .logoutPopup p:hover {
    color: red;
  }

  .paginationContainer {
    display: flex;
    justify-content: end;
    margin-top: 10px;
    align-items: center;
  }
  
  .currentPageCont{
    border: 1px solid #bebebe;
    height: fit-content;
    min-height: 2.8rem;
    width: fit-content;
    min-width: 2rem;
    padding:0px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .currentPageCont span{
    font-size: 1.4rem !important;
    font-weight: 600 !important;
  }
  
  
  .paginationContainer button {
    width: 7rem;
    background-color: #4caf50;
    border: none;
    color: white;
    padding:5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    margin: 0 5px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .paginationContainer button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }


@media only screen and (max-width: 749px) {
    .bottomContainer{
        max-width: 100%;/*sri*/
        min-height: 90rem;
        padding:1rem 2rem;
        /* background-color: yellow;   */
        overflow-y: scroll; 
    }
    .bottomContainerSub{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .bottomContTableQuot{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap:3rem;
        /* background-color: blue; */
        overflow-y: scroll;
    }
    .bottomContTable{
        width: 100%;
        height: 33rem;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }
    .quoteImgCont{
        width: 70%;
        height: fit-content;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* background-color: #36B14D; */
    }
}

@media only screen and (max-width: 555px) {
    .quoteImgCont{
        width: 90%;
        height: fit-content;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* background-color: #36B14D; */
    }


}

@media only screen and (max-width: 500px) {
    .headerContainer{
        max-width: 100%;/*sri*/
        height: 4rem;
        /* background-color: #fff; */
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding:0 1rem;
        }
        .headerContainer img{
            /* width:10rem; */
            height: 3.5rem;
        }
        .user{
            width:2rem !important;
            height: 2rem !important;
            cursor: pointer; 
        }
        .imgContainer{
            max-width: 100%;/*sri*/
            min-height: 100%;
            overflow: hidden;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            }
            
    .bottomContTableQuot{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap:3rem;
        /* background-color: blue; */
        overflow-y: scroll;
    }
    .bottomContTable{
        width: 100%;
        height: 36rem;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }
    .quoteImgCont{
        width: 90%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* background-color: #36B14D; */
    }
}

@media only screen and (max-width: 350px) {
    .imgContainer{
        max-width: 100%;/*sri*/
        height: 15rem;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        }
}

@media only screen and (max-width: 400px) {

    .imgContainer span {
        position: absolute;
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%);
        color: #000;
        font-family: Raleway;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: 70px; 
    }
    .bottomContTable{
        width: 40rem;
        height: 36rem;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        /* background-color: #FFF; */
    }
    .quoteImgCont{
        width: 90%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* background-color: #36B14D; */
    }
}

/* @media only screen and (max-width: 370px) {
    .headerContainer{
        max-width: 144rem;
        height: 2rem;
        background-color: #fff;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding:0 0.5rem;
        }
        .headerContainer img{
            width:8rem;
            height: 3rem;
            margin-top: 0.8rem;
        }
        .user{
            width:1.8rem !important;
            height: 1.8rem !important;
            cursor: pointer;
            margin-top: 0.8rem; 
        }
    } */

    /* @media only screen and (max-width: 320px) {
        .headerContainer{
            max-width: 144rem;
            height: 1rem !important;
            display:flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding:0 0.5rem;
            }
            .headerContainer img{
                width:8rem;
                height: 3rem;
                margin-top: 1.5rem;
            }
            .user{
                width:1.8rem !important;
                height: 1.8rem !important;
                cursor: pointer;
                margin-top: 1.8rem; 
            }
        } */
    

@media only screen and (max-width: 300px) {

    .bottomContBtn{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* background-color: violet; */
    }
    .quoteImgCont{
        width: 95%;
        height: fit-content;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* background-color: #36B14D; */
    }
}

/* @media only screen and (max-width: 612px) {
    .bottomContainerSub{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .bottomContainerSub div:first-child span{
        color: #000;
        font-family: Raleway !important;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: 4rem;
    }
    
    .bottomContainerSub div:first-child{
        width: 40rem;
    }
} */

/* @media only screen and (max-width: 553px) {
    .bottomContainerSub div:first-child span{
        color: #000;
        font-family: Raleway !important;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: 4rem;
    }
    
    .bottomContainerSub div:first-child{
        width: auto;
    }
    
} */

/* @media only screen and (max-width: 553px) {
    .bottomContainerSub div:nth-child(2) span{
        color: rgba(0, 0, 0, 0.75);;
        font-family: 'Inter', sans-serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
    }
    
    .bottomContainerSub div:nth-child(2){
        width: 35rem;
    }
}

@media only screen and (max-width: 500px) {
    .headerContainer{
        max-width: 144rem;
        height: 4.5rem;
        background-color: #fff;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding:0 2rem;
        }

        .imgContainer{
            max-width: 144rem;
            height: 33rem;
            overflow: hidden;
            }
            .bottomContainer{
                max-width: 144rem;
                height: fit-content;
                background-color: #fff;   
            }
}

@media only screen and (max-width: 423px) {
    .bottomContainerSub div:first-child span{
        color: #000;
        font-family: Raleway !important;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3rem;
    }
    
    .bottomContainerSub div:first-child{
        width: auto;
    }

    .bottomContainerSub div:nth-child(2){
        width: auto;
    }
    
}

@media only screen and (max-width: 400px) {
    .headerContainer{
        max-width: 144rem;
        height: 4.5rem;
        background-color: #fff;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding:0 1.5rem;
        }
        
        .headerContainer img{
            width:8rem;
            height: 3rem;
        }
        
        .headerBtnCont{
            display: flex;
            flex-direction: row;
            gap:0.8rem;
        }

        .headerBtnCont button:first-child{
            width: 6rem;
            height: 2.4rem;
            background-color:#fff;
            border: none ;
            color: #000;
            font-family: Raleway;
            font-size: 9px;
            font-weight: 600;
            cursor: pointer;
        }
        
        .headerBtnCont button:nth-child(2){
            width: 6rem;
            height: 2.4rem;
            background-color: #36B14D;
            border: 1px solid #36B14D ;
            border-radius: 3px;
            color: #FFF;
            font-family: Raleway;
            font-size: 9px;
            font-weight: 600;
            cursor: pointer;
        }
}

@media only screen and (max-width: 363px) {
    .bottomContainerSub{
        width: 100%;
        height: 100%;
        padding: 2.5rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    } 

    .bottomContainerSub div:first-child span{
        color: #000;
        font-family: Raleway !important;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.8rem;
    }
    .bottomContainerSub div:nth-child(2) span{
        color: rgba(0, 0, 0, 0.75);;
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
    }

    .bottomContainerSub div:nth-child(2){
        width: 25rem;
    }
}

@media only screen and (max-width: 300px) {
    .bottomContainerSub div:nth-child(2){
        width: 20rem;
    }

    .headerContainer{
        max-width: 144rem;
        height: 4.5rem;
        background-color: #fff;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding:0 1rem;
        }
        
        .headerContainer img{
            width:7rem;
            height: 2.5rem;
        }
        
        .headerBtnCont{
            display: flex;
            flex-direction: row;
            gap:0.5rem;
        }
}

@media only screen and (max-width: 264px) {
    .bottomContainerSub{
        width: 100%;
        height: 100%;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    } 
    .bottomContainerSub div:first-child span{
        color: #000;
        font-family: Raleway !important;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.5rem;
    }
}

@media only screen and (max-width: 242px) {
    .bottomContainerSub div:nth-child(2){
        width: 17rem;
    }
} */