.quotationPageContainer{
    height: 100%;
    width: 100%;
    background-color:#fff
}

.quotationPageContainerSub{
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    /* justify-content:space-between; */
    gap: 1rem;
    /* background-color:red; */
}

.headerContainer{
    max-width: 100%;/*sri*/
    height: 5rem;
    /* background-color: aqua; */
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    padding:1rem 2rem;
}

.headerContainer img{
    width:10rem;
    height: 3.5rem;
    cursor: pointer;
}

.midContainer{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1.5rem;
    padding: 1rem;
    /* background-color: blueviolet; */
}

.quotationImgCont{
    width: 35rem;
    height: fit-content;
    background-color: bisque;
}

.midContainer div:nth-child(3){
    width: 30rem;
}

.midContainer button{
    width: 15rem;
    height: 2.8rem;
    background-color: #36B14D;
    border: 1px solid #36B14D ;
    border-radius: 3px;
    color: #FFF;
    font-family: Raleway;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer; 
}

.midContainer button:hover{
    background-color:#42dc5e;
    border: 1px solid #42dc5e;
}

@media only screen and (max-width: 530px) {
    /* .quotationPageContainerSub{
        width: 100%;
        height:100%;
        display: flex;
        flex-direction: column;
        justify-content:space-between;
        background-color:blue;
    }
    .midContainer{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:1.5rem;
        padding: 1rem;
        background-color: blueviolet;
    } */

    .quotationImgCont{
        width: 90%;
        height: fit-content;
        background-color: bisque;
    }    
}

@media only screen and (max-width: 500px) {
    
    .quotationPageContainer{
        height: 100vh;
        width: 100%;
        background-color:#fff
    }
    .headerContainer img{
        width:9rem;
        height: 2.8rem;
    }
    
}

@media only screen and (max-width: 375px) {
    .midContainer div:nth-child(3){
        width: 25rem;
    }
    
}

@media only screen and (max-width: 325px) {
    .midContainer div:nth-child(3){
        width: 20rem;
    }   
}

@media only screen and (max-width: 270px) {
    .midContainer div:nth-child(3){
        width: 16rem;
    }   
}