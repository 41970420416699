.homePageContainer{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: yellow; */
}

.headerContainer{
max-width: 100%;/*sri*/
height: 8rem;
background-color: #fff;
display:flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
padding:0 2rem;
}

.headerContainer img{
    /* width:10rem; */
    height: 4rem;
}

.headerBtnCont{
    display: flex;
    flex-direction: row;
    gap:1rem;
}

.headerBtnCont button:first-child{
    width: 6rem;
    height: 2.8rem;
    background-color:#fff;
    border: none ;
    color: #000;
    font-family: Raleway;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
}

.headerBtnCont button:nth-child(2){
    width: 6rem;
    height: 2.8rem;
    background-color: #36B14D;
    border: 1px solid #36B14D ;
    border-radius: 3px;
    color: #FFF;
    font-family: Raleway;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
}

.headerBtnCont button:nth-child(2):hover{
    background-color:#42dc5e;
    border: 1px solid #42dc5e;
}

.imgContainer{
max-width: 100%;/*sri*/
height: 25rem;
/* overflow: hidden; */
}

.imgContainer img {
    width: 100%;
    height: 100%; 
    object-fit: cover;
    /* display: block;  */
}

.bottomContainer{
    max-width: 100%;/*sri*/
    height: 44rem;
    background-color: #fff;   
}

.bottomContainerSub{
    width: 100%;
    height: 100%;
    padding: 3.5rem;
    padding-left: 10rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: space-between;
}

.bottomContainerSub div:first-child span{
    color: #000;
    font-family: Raleway !important;
    font-size: 5.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 6.5rem;
}

.bottomContainerSub div:first-child{
    width: 80rem;
    /* background-color: red; */
}

.bottomContainerSub div:nth-child(2) span{
    color: rgba(0, 0, 0, 0.75);;
    font-family: 'Inter', sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
}

.bottomContainerSub div:nth-child(2){
    width: 48rem;
    /* background-color: yellow; */
}

.bottomContainerSub button{
    width: fit-content;
    height: 5rem;
    background-color: #36B14D;
    border: 1px solid #36B14D ;
    border-radius: 3px;
    color: #FFF;
    font-family: Raleway;
    font-size: 3.5rem;
    font-weight: 600;
    cursor: pointer;
    padding: 0 2rem;
}

.bottomContainer button:hover{
    background-color:#42dc5e;
    border: 1px solid #42dc5e;
}

@media only screen and (max-width: 1000px) {
    .bottomContainerSub div:first-child span{
        color: #000;
        font-family: Raleway !important;
        font-size: 4.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 5rem;
    }
    
    .bottomContainerSub div:first-child{
        width: 60rem;
        /* background-color: red; */
    }
    
    .bottomContainerSub div:nth-child(2) span{
        color: rgba(0, 0, 0, 0.75);;
        font-family: 'Inter', sans-serif;
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 400;
    }
    
    .bottomContainerSub div:nth-child(2){
        width: 48rem;
        /* background-color: yellow; */
    }
}

@media only screen and (max-width: 849px) {
    .bottomContainerSub div:first-child span{
        color: #000;
        font-family: Raleway !important;
        font-size: 4rem;
        font-style: normal;
        font-weight: 600;
        line-height: 4.5rem;
    }
    
    .bottomContainerSub div:first-child{
        width: 50rem;
        /* background-color: red; */
    }
    
    .bottomContainerSub div:nth-child(2) span{
        color: rgba(0, 0, 0, 0.75);;
        font-family: 'Inter', sans-serif;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
    }
    
    .bottomContainerSub div:nth-child(2){
        width: 43rem;
        /* background-color: yellow; */
    }
}

@media only screen and (max-width: 749px) {
    .bottomContainerSub{
        width: 100%;
        height: 100%;
        padding: 3.5rem;
        padding-left: 6rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
}

@media only screen and (max-width: 612px) {
    .bottomContainerSub{
        width: 100%;
        height: 100%;
        padding: 3.5rem;
        padding-left: 3.5rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .bottomContainerSub div:first-child span{
        color: #000;
        font-family: Raleway !important;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: 4rem;
    }
    
    .bottomContainerSub div:first-child{
        width: 40rem;
        /* background-color: red; */
    }
}

@media only screen and (max-width: 553px) {
    .bottomContainerSub div:first-child span{
        color: #000;
        font-family: Raleway !important;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: 4rem;
    }
    
    .bottomContainerSub div:first-child{
        width: auto;
        /* background-color: red; */
    }
    
}

@media only screen and (max-width: 553px) {
    .bottomContainerSub div:nth-child(2) span{
        color: rgba(0, 0, 0, 0.75);;
        font-family: 'Inter', sans-serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
    }
    
    .bottomContainerSub div:nth-child(2){
        width: 35rem;
        /* background-color: aqua; */
    }
}

@media only screen and (max-width: 500px) {
    .headerContainer{
        max-width: 100%;/*sri*/
        height: 4.5rem;
        background-color: #fff;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding:0 2rem;
        }

        .imgContainer{
            max-width: 100%;/*sri*/
            height: 33rem;
            /* overflow: hidden; */
            }
            .bottomContainer{
                max-width: 100%;/*sri*/
                height: fit-content;
                background-color: #fff;   
            }
}

@media only screen and (max-width: 423px) {
    .bottomContainerSub div:first-child span{
        color: #000;
        font-family: Raleway !important;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3rem;
    }
    
    .bottomContainerSub div:first-child{
        width: auto;
        /* background-color: red; */
    }

    .bottomContainerSub div:nth-child(2){
        width: auto;
        /* background-color: aqua; */
    }
    
}

@media only screen and (max-width: 400px) {
    .headerContainer{
        max-width: 100%;/*sri*/
        height: 4.5rem;
        background-color: #fff;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding:0 1.5rem;
        }
        
        .headerContainer img{
            /* width:8rem; */
            height: 4rem;
        }
        
        .headerBtnCont{
            display: flex;
            flex-direction: row;
            gap:0.8rem;
        }

        .headerBtnCont button:first-child{
            width: 6rem;
            height: 2.4rem;
            background-color:#fff;
            border: none ;
            color: #000;
            font-family: Raleway;
            font-size: 1rem;
            font-weight: 600;
            cursor: pointer;
        }
        
        .headerBtnCont button:nth-child(2){
            width: 6rem;
            height: 2.4rem;
            background-color: #36B14D;
            border: 1px solid #36B14D ;
            border-radius: 3px;
            color: #FFF;
            font-family: Raleway;
            font-size: 1.1rem;
            font-weight: 600;
            cursor: pointer;
        }
}

@media only screen and (max-width: 363px) {
    .bottomContainerSub{
        width: 100%;
        height: 100%;
        padding: 2.5rem;
        /* padding-left: 3.5rem; */
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    } 

    .bottomContainerSub div:first-child span{
        color: #000;
        font-family: Raleway !important;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.8rem;
    }
    .bottomContainerSub div:nth-child(2) span{
        color: rgba(0, 0, 0, 0.75);;
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
    }

    .bottomContainerSub div:nth-child(2){
        width: 25rem;
        /* background-color: aqua; */
    }
}

@media only screen and (max-width: 300px) {
    .bottomContainerSub div:nth-child(2){
        width: 20rem;
    }

    .headerContainer{
        max-width: 100%;/*sri*/
        height: 4.5rem;
        background-color: #fff;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding:0 1rem;
        }
        
        .headerContainer img{
            /* width:7rem; */
            height: 3.5rem;
        }
        
        .headerBtnCont{
            display: flex;
            flex-direction: row;
            gap:0.5rem;
        }
}

@media only screen and (max-width: 264px) {
    .bottomContainerSub{
        width: 100%;
        height: 100%;
        padding: 2rem;
        /* padding-left: 3.5rem; */
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    } 
    .bottomContainerSub div:first-child span{
        color: #000;
        font-family: Raleway !important;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.5rem;
    }
}

@media only screen and (max-width: 242px) {
    .bottomContainerSub div:nth-child(2){
        width: 17rem;
    }
}