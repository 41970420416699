.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    z-index: 10;
  }

  .modalContent {
    background: white;
    padding: 30px;
    border-radius: 8px;
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    overflow-y: scroll;
  }

  .circle {
    width: 30px;
    height: 30px;
    background-color:#36B14D; /* You can set the color you prefer */
    border-radius: 50%;
  }

  .modalContent span{
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }

  .modalContent input{
    height: 30px;
    border-radius: 5px;
    border: 1px solid #36B14D;
    background: #FFF;
    font-size: 1.1rem;
    font-weight: 500;
    padding-left: 4px;
  }

  .modalContent button{
    width: 16rem;
    height: 2.8rem;
    background-color: #36B14D;
    border: 1px solid #36B14D ;
    border-radius: 3px;
    color: #FFF;
    font-family: Raleway;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
}

.modalContent button:hover{
    background-color:#42dc5e;
    border: 1px solid #42dc5e;
}
