.settingsPageCont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    /* padding-bottom: 2rem; */
    /* overflow: hidden; */
    background-color: #F8F9FB;
}

.barCont{
    width: 100%;
    display: flex;
    flex-direction: row;
    /* background-color: red; */
}

.barCont div span{
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

.btnCont{
  width:fit-content !important;
  height: fit-content;
  display: flex;
  flex-direction: row !important;
  gap:1rem;
  margin-left: auto;
}

.barContbutton{
    width: 8rem;
    height: 2.8rem;
    background-color: #36B14D;
    border: 1px solid #36B14D ;
    border-radius: 3px;
    color: #FFF;
    /* font-family: Raleway; */
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
}

.barContbutton:hover{
    background-color:#42dc5e;
    border: 1px solid #42dc5e;
}

.barContRedbutton{
  width: 8.5rem;
  height: 2.8rem;
  background-color: #e1393f;
  border: 1px solid #e1393f ;
  border-radius: 3px;
  color: #FFF;
  /* font-family: Raleway; */
  font-size: 1.4rem;
  font-weight: 600;
  cursor: pointer;
}

.barContRedbutton:hover{
  background-color:#e8252bd6;
  border: 1px solid #e8252bd6;
}

.bottomCont{
    width:100%;
    height: 100%;
    overflow-y: scroll;
}

.partCountCont{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:2rem;
    /* background-color: burlywood; */
    /* overflow: scroll; */
}

.selectionCont{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap:1rem;
}

.selectionCont div{
    width:13rem;
    display: flex;
    flex-direction: column;
}

.selectionCont div label{
    color: #747474;
    font-family: Inter;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 3px;
}

.selectionCont div select{
    height: 3rem;
    border: 0;
    background-color: #fff;
    border-radius: 4px;
}

.tableCont{
    width:100%;
    height: 100%;
    border-radius: 10px;
    overflow-y: scroll;
    
}
.tableContSub{
    width: 100%;
    height: 100%;
}

/* Modal styles */
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: rgba(0,0,0,0.5); /* semi-transparent background */
  }
  
  /* Modal content styles */
  .modalContent {
    width: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap:1.5rem
  }
  
  /* Close button styles */
  .close {
    color: #aaa;
    align-self: flex-end;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .modalContentSub{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap:2rem;
    flex-wrap: wrap;
  }

  .modalContentSub div{
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 13rem;
  }

  .modalContentSub div label{
    color: #666;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
  }

  .modalContentSub div input{
    color: #000;
    font-family: Inter;
    font-size: 1.4rem;
    text-align: right;
    padding-right: 5px;
  }

  .modalBottomCont{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .errMsgModal{
    color: red;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    margin-right: 0;
    align-self: self-start !important;
  }
  

@media only screen and (max-width: 850px) {
    .tableCont{
        width:100%;
        height: 100%;
        border-radius: 10px;
        overflow: scroll;
    } 
    .tableContSub{
        width:70rem;
        height: 100%;   
    }
}

@media only screen and (max-width: 500px) {
    .modalContent {
        width: 95%;
        height: 75%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap:1.5rem;
      }
      .modalContentSub{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        gap:2rem;
        flex-wrap: wrap;
        overflow-y: scroll;
      }
    
}