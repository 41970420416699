.homeTypePageContainer{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    /* background-color: yellow; */
}

.imgContainer{
    width: 60rem;
    height: 100%;
    
    /* overflow: hidden; */
 }

.imgContainer img {
        width: 100%;
        height: 100%; 
        object-fit: cover;
}

.rightCont{
 width: 100%;
 height: 100%;
 padding: 3rem;
 background-color: #fff;
 overflow: scroll;
}

.rightContSub{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:end; 
    gap: 8rem;
    /* background-color: aqua; */
    margin-top: 15%;
}

.rightContSub div:nth-child(1) span{
    color: #000;
text-align: center;
font-family: Inter;
font-size: 3rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.rightContSub div:nth-child(2){
display: flex;
flex-direction: row;
gap:5rem;
}

.rightContSub div:nth-child(3){
    width:30rem;
       }

.rightContSub div:nth-child(4){
 align-self: flex-end;
    }

    @media only screen and (max-width: 755px) {
        .imgContainer{
            width: 40rem;
            height: 100%;
            overflow: hidden;
         }
         .rightContSub div:nth-child(1) span{
            color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        }
    }

    @media only screen and (max-width: 755px) {
        .rightContSub{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content:end; 
            gap: 6rem;
        }
        .rightContSub div:nth-child(2){
            display: flex;
            flex-direction: column;
            gap:3rem;
            }
    }

    @media only screen and (max-width: 640px) {
        .rightContSub div:nth-child(1) span{
            color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        }
        .rightContSub div:nth-child(3){
            width:20rem;
               }
    }

    @media only screen and (max-width: 610px) {
         .rightContSub{
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content:end; 
            gap: 3rem;
            /* background-color: aqua; */
            margin-top: 15%;
        }
        
    }

    @media only screen and (max-width: 500px) {
        .homeTypePageContainer{
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            /* background-color: yellow; */
        }

        .imgContainer{
            width: 100%;
            height: 32rem;
            overflow: hidden;
         }
         .rightContSub{
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content:end; 
            gap: 5rem;
            /* background-color: aqua; */
            margin-top: 0;
        }
        
    }