.propertyTypePageContainer{
    height: 100%;
    width: 100%;
    padding: 2rem 2rem;
    background-color: white;
}

.propertyTypePageContainerSub{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:start; 
    gap: 1rem;
    /* background-color: antiquewhite; */
}

.propertyTypePageContainerSub div:nth-child(1) span{
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.imgnBtnContainer{
    width: 100%;
    height: fit-content;
    min-height: 37rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    /* background-color: aqua; */
}

.imgnBtnContainer div{
    width:30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap:1rem;
    align-items: center;
    padding: 0 1rem;
    /* background-color:red; */
}

.imgnBtnContainer div img{
width: 100%;
height: 88%;
cursor: pointer;
object-fit: cover;
}

.bottomContainer{
    width: 100%;
    height: auto;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: blueviolet; */
}

.bottomContainer button{
    align-self: flex-end;
}

.bottomContainer div{
    width:35rem;
    /* height: 5rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1.5rem;
    /* background-color: yellowgreen; */
}

.bottomContainer div span{
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 2.3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: noWrap;
}

.bottomContainer div input{
    width:12rem;
    padding: 10px;
    border: 2px solid #36B14D;
    border-radius: 5px; 
    outline: none;
    background-color: transparent;
}

@media only screen and (max-width: 770px) {
    .imgnBtnContainer div{
        width:30%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
        /* background-color:red; */
    }
}

@media only screen and (max-width: 640px) {
    .imgnBtnContainer div{
        max-width:18rem;
        height:100%;
        display: flex;
        flex-direction: column;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
        /* background-color:white; */
    }

    .imgnBtnContainer div button{
     width: 14rem;
    }

    .bottomContainer div{
        width:25rem;
        /* height: 5rem; */
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:1.5rem;
        /* background-color: yellowgreen; */
    }

    .bottomContainer div input{
        width:10rem;
        padding: 10px;
        border: 2px solid #36B14D;
        border-radius: 5px; 
        outline: none;
        background-color: transparent;
    }
}

@media only screen and (max-width: 580px) {
    .imgnBtnContainer div{
        max-width:16rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
        /* background-color:white; */
    }

    .imgnBtnContainer div button{
     width: 12rem;
    }

    .bottomContainer div{
        width:20rem;
        /* height: 5rem; */
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:1.5rem;
        /* background-color: yellowgreen; */
    }
    .bottomContainer div input{
        width:8rem;
        padding: 10px;
        border: 2px solid #36B14D;
        border-radius: 5px; 
        outline: none;
        background-color: transparent;
    }
}

@media only screen and (max-width: 520px) {

    .propertyTypePageContainerSub{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:start; 
        gap: 1rem;
        /* background-color: antiquewhite; */
    }
    .imgnBtnContainer{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:3rem;
        /* background-color: aqua; */
        
    }

    .imgnBtnContainer div{
        min-width:100%;
        height: 38rem;
        display: flex;
        flex-direction: column;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
        /* background-color:white; */
    }

    .imgnBtnContainer div button{
     width: 60%;
    }
}


@media only screen and (max-width: 490px) {
    .propertyTypePageContainerSub div:nth-child(1) span{
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .bottomContainer{
        width: 100%;
        height: auto;
        padding: 1rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap:2rem;
        /* background-color: blueviolet; */
    }
    .bottomContainerResponsive span{
        color: #000 !important;
        text-align: center;
        font-family: Inter;
        font-size: 1.8rem !important;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .bottomContainerResponsiveBtn{
        width: 100% !important;
        height: auto !important;
        padding: 1rem 1rem !important;
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: space-between !important;
    }
}

@media only screen and (max-width: 370px) {
    .imgnBtnContainer div{
        min-width:100%;
        height: 38rem;
        display: flex;
        flex-direction: column;
        gap:1rem;
        align-items: center;
        padding: 0rem;
    }

}

@media only screen and (max-width: 260px) {
.bottomContainerResponsive{
    width:20rem !important;
    /* height: 5rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1.5rem;
}
}