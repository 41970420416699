.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
  }

  .modalPreview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    z-index: -10;
  }

  /* .modalPrint {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    z-index: -10;
  } */
  
  .modalContent {
    background: white;
    padding: 5px;
    border-radius: 8px;
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    overflow-y: scroll;
  }

  .modalContentSub{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border: 1.3px solid black;
  }
  .headerCont{
    width: 100%;
    height: 5rem;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
  }

  .headerCont span{
    font-size: 1.8rem;
    font-weight: 700;
    color: #36B14D;
    margin-bottom: 0.8rem;
  }
  .headerCont img{
    align-self: baseline;
    height: 3rem !important;
    width: 7rem !important;
  }

  .detailsCont{
    width: 100%;
    height: 5rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1.3px solid #000;
    border-left: none;
    border-right: none;
    /* background-color: yellow; */
  }

  .addressCont{
    width: 40%;
    height: 100%;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .addressContTop{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    /* background-color: #36B14D; */
  }

  .addressContBottom{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    /* background-color:orchid; */
  }

  .addressContBottomLeft{
    display: flex;
    flex-direction: column;
    font-size: 0.4rem;
    gap:0.2rem;
    align-items: end;
  }

  .addressContBottomRight{
    display: flex;
    flex-direction: column;
    font-size: 0.4rem;
    font-weight: 500;
    gap:0.2rem;
  }

  .infoCont{
    width: 50%;
    height: 100%;
    padding-right: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .infoContTop{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    /* background-color:orchid; */
  }

  .infoContBottom{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    /* background-color:aquamarine; */
  }


  .heading {
    font-size: 0.6rem;
    font-weight: 600;
    color: #000;
  }
  
  .headerOrgAddress {
    font-size: 0.4rem;
    color: #000;
  }

  .infoCont{
    width: 40%;
    height: 100%;
  }

  .tableCont {
    width: 100% !important;
    height: fit-content;
    /* background-color: aqua; */
    margin: 0 auto;
    margin-top: 0.5rem;
  }

  .tableCont table{
    border-spacing: 0;
  }
  
  .tableCont table thead th {
    padding-top: 0.5px;
    padding-bottom: 0.5px;
    padding-left: 4px;
    text-align: left;
    font-weight: 600;
    font-size: 5px;
    color: #000;
    background-color: transparent;
    border-spacing: 0;
    border: 1px solid #000;
    border-bottom: none;
    border-left: none;
  }

  .tableCont table thead th:last-child {
    padding-top: 0.5px;
    padding-bottom: 0.5px;
    padding-left: 4px;
    text-align: left;
    font-weight: 600;
    font-size: 5px;
    color: #000;
    background-color: transparent;
    border-spacing: 0;
    border: 1.2px solid #000;
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  .firstRow td{
    padding-top: 0.5px !important;
    padding-bottom: 0.5px !important;
    padding-left: 4px !important;
    text-align: center !important;
    font-size: 5px !important;
    font-weight: 600 !important;
    color: #fff !important;
    border: 1px solid #000 !important;
    background-color: #36B14D;
    border-bottom: none !important;
    border-left: none !important;
  }

  .firstRow td:last-child{
    padding-top: 0.5px !important;
    padding-bottom: 0.5px !important;
    padding-left: 4px !important;
    text-align: center !important;
    font-size: 5px !important;
    font-weight: 600 !important;
    color: #fff !important;
    border: 1px solid #000 !important;
    background-color: #36B14D;
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
  }
  
  
  .tableCont table tbody tr td {
    padding-top: 0.5px;
    padding-bottom: 0.5px;
    padding-left: 4px;
    text-align: left;
    font-size: 5px;
    color: #000;
    border: 1px solid #000;
    border-bottom: none;
    border-left:none;
  }

  .tableCont table tbody tr:last-child td {
    padding-top: 0.5px;
    padding-bottom: 0.5px;
    padding-left: 4px;
    text-align: left;
    font-size: 5px;
    color: #000;
    border-bottom: 1.2px solid #000;
  }

  .tableCont table tbody tr td:last-child {
    padding-top: 0.5px;
    padding-bottom: 0.5px;
    padding-left: 4px;
    text-align: left;
    font-size: 5px;
    color: #000;
    border-right: none;
  }

  .descDataCont{
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: 600;
    font-size: 5px;
    color: #000;
  }

  .descDataCont span{
    font-size: 5px;
    font-weight: normal;
    color: #000;
  }

  .numberCont{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .tableCont table tfoot tr td {
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 4px;
    text-align: right;
    font-weight: 600;
    font-size: 8px;
    color: #fff;
    border: 1px solid #000;
    border-top: none;
    border-right: none;
  }

  .footerCont{
    width: 100%;
    height: fit-content;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap:0.3rem;
    margin-top: 1rem;
    /* background-color: yellow; */
  }

  .footerContspan{
    font-size: 0.5rem;
    font-weight: 600;
    color: #333;
    text-decoration: underline;
  }

  .footerContBottom{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:0.2rem;
    /* background-color: #e2e2e2; */
  }


  .footerContBottomSub{
    display: flex;
    flex-direction: row;
    font-size: 0.4rem;
    gap:0.2rem;
  }

  .footerContBottomRight{
    display: flex;
    flex-direction: column;
    font-size: 0.4rem;
    gap:0.2rem;
  }

  .printBtn{
    width: 55%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }

  @media only screen and (max-width: 749px) {
  .headerCont{
    width: 100%;
    height: 7rem;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
  } 
  .modalContent {
    background: white;
    padding: 5px;
    border-radius: 8px;
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow-y: scroll; */
  }
}