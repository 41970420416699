.dashboardPageCont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    /* padding-bottom: 2rem; */
    overflow-y: scroll;
    background-color: #F8F9FB;
}

.headingCont{
    padding-top: 1rem;
}

.headingCont span{
    color: #364153;
    font-family: Raleway;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.cardsCont{
    height: 14.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:4rem;
    /* background-color: yellowgreen; */
}

.card{
    height: 100%;
    min-width: 22rem;
    flex: 1;
    border-radius: 8px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.cardTop{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: aqua; */
}
.cardTopLeft{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
}

.cardTopLeft span:nth-child(1){
    color: #364153;
    font-family: Inter;
    font-size: 3.1rem;
    font-style: normal;
    font-weight: 600;
    white-space: nowrap;
}

.cardTopLeft span:nth-child(2){
    color: #364153;
    font-family: Raleway;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    white-space: nowrap;
}

.cardTop img{
    width: 4rem;
    height: 4rem;
    /* object-fit: cover; */
}

.cardBottom{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.cardBottomSub{
    height: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    border-radius: 7.926px;
    background: #F8F9FB;
}

.cardBottomSub span:nth-child(1){
    color: #FD5942;
    font-family: Inter;
    font-size: 12.681px;
    font-style: normal;
    font-weight: 600;
    padding-left: 2rem;
    white-space: nowrap;
}


.cardBottomSub span:nth-child(2){
    color: #6B6B6B;
    font-family: Inter;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
}

.bottomCont{
    height: 100%;
    width: 100%;
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    gap:1rem;
    padding: 1rem;
    border-radius: 8px;
    background-color: #fff;
}

.bottomCont span:nth-child(1){
    color: #374253;
    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
}

.bottomCont span:nth-child(2){
    color: #6B6B6B;
    font-family: Inter;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
}

.chartCont{
    width: 100%;
    height: 100%;
    /* background-color: aqua; */
}

.responsiveCont{
    margin-left: -4rem;
    height: 100%;
}

@media only screen and (max-width: 1160px) {
    .cardBottomSub span:nth-child(1){
        color: #FD5942;
        font-family: Inter;
        font-size: 12.681px;
        font-style: normal;
        font-weight: 600;
        padding-left: 1rem;
        white-space: nowrap;
    }
    
}

@media only screen and (max-width: 1122px) {
    .cardBottomSub span:nth-child(1){
        color: #FD5942;
        font-family: Inter;
        font-size: 12.681px;
        font-style: normal;
        font-weight: 600;
        padding-left: 0.5rem;
        white-space: nowrap;
    }
    
}

@media only screen and (max-width: 1104px) {
    .cardsCont{
        height: 14.5rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap:3rem;
        /* background-color: yellowgreen; */
    }
    .cardBottomSub span:nth-child(1){
        color: #FD5942;
        font-family: Inter;
        font-size: 12.681px;
        font-style: normal;
        font-weight: 600;
        padding-left: 0;
        white-space: nowrap;
    }
    
}

@media only screen and (max-width: 1067px) {
    .cardsCont{
        height: 14.5rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap:2rem;
        /* background-color: yellowgreen; */
    }    
}

@media only screen and (max-width: 1042px) {
.cardBottomSub{
    height: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 7.926px;
    background: #F8F9FB;
}
}

@media only screen and (max-width: 1019px) {
    .cardsCont{
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap:4rem;
        /* background-color: yellowgreen; */
    }
    .card{
        height: 100%;
        min-width: 22rem;
        flex: 1;
        border-radius: 8px;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: #fff;
    }
    .cardBottomSub{
        height: fit-content;
        display: flex;
        flex-direction: column-reverse;
        align-items: baseline;
        gap: 1rem;
        padding: 1rem;
        border-radius: 7.926px;
        background: #F8F9FB;
    }
    }

    @media only screen and (max-width: 1000px) {
        .card{
            height: 100%;
            min-width: 18rem;
            flex: 1;
            border-radius: 8px;
            padding: 1.5rem;
            display: flex;
            flex-direction: column;
            background-color: #fff;
        }
        .cardTopLeft span:nth-child(1){
            color: #364153;
            font-family: Inter;
            font-size: 2.6rem;
            font-style: normal;
            font-weight: 600;
            white-space: nowrap;
        }
    }

    @media only screen and (max-width: 979px) {
        .cardTop{
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            align-items: baseline;
            /* background-color: aqua; */
        }
    }

    @media only screen and (max-width: 855px) {
        .cardTopLeft span:nth-child(2){
            color: #364153;
            font-family: Raleway;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 600;
            white-space: pre-wrap;
        }
        .cardBottomSub span:nth-child(2){
            color: #6B6B6B;
            font-family: Inter;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            white-space: pre-wrap;
        }
    }

    @media only screen and (max-width: 797px) {
        .card{
            height: 100%;
            min-width: 15rem;
            flex: 1;
            border-radius: 8px;
            padding: 1.5rem;
            display: flex;
            flex-direction: column;
            background-color: #fff;
        }
    }

    @media only screen and (max-width: 710px) {
        .card{
            height: 100%;
            min-width: 10rem;
            flex: 1;
            border-radius: 8px;
            padding: 1.5rem;
            display: flex;
            flex-direction: column;
            background-color: #fff;
        }
        .cardTopLeft span:nth-child(1){
            color: #364153;
            font-family: Inter;
            font-size: 2.3rem;
            font-style: normal;
            font-weight: 600;
            white-space: nowrap;
        }
    }

    @media only screen and (max-width: 700px) {
        
        .cardsCont{
            height: fit-content;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap:3rem;
            /* background-color: yellowgreen; */
        }
        .card{
            height: 100%;
            min-width: 22rem;
            flex: 1;
            border-radius: 8px;
            padding: 1.5rem;
            display: flex;
            flex-direction: column;
            background-color: #fff;
        }
        
        .cardTop{
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            /* background-color: aqua; */
        }
        .cardTopLeft{
            display: flex;
            flex-direction: column;
            gap:0.5rem;
        }
        
        .cardTopLeft span:nth-child(1){
            color: #364153;
            font-family: Inter;
            font-size: 3.1rem;
            font-style: normal;
            font-weight: 600;
            white-space: nowrap;
        }
        
        .cardTopLeft span:nth-child(2){
            color: #364153;
            font-family: Raleway;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 600;
            white-space: nowrap;
        }
        
        .cardTop img{
            width: 4rem;
            height: 4rem;
            /* object-fit: cover; */
        }
        
        .cardBottom{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: end;
        }
        
        .cardBottomSub{
            height: 60%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2rem;
            padding: 1rem;
            border-radius: 7.926px;
            background: #F8F9FB;
        }
        
        .cardBottomSub span:nth-child(1){
            color: #FD5942;
            font-family: Inter;
            font-size: 12.681px;
            font-style: normal;
            font-weight: 600;
            padding-left: 2rem;
            white-space: nowrap;
        }
        
        
        .cardBottomSub span:nth-child(2){
            color: #6B6B6B;
            font-family: Inter;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            white-space: nowrap;
        }

        .responsiveCont{
            margin-left: -4rem;
            height: 25rem !important;
        }
    }

    @media only screen and (max-width: 650px) {
    .chartCont{
        width: 100%;
        height: fit-content;
        overflow-x: scroll;
        /* background-color: aqua; */
    }
}

    @media only screen and (max-width: 500px) {

    .dashboardPageCont{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        gap:1.5rem;
        /* padding-bottom: 2rem; */
        overflow-y: scroll;
        background-color: #F8F9FB;
    }
}

    @media only screen and (max-width: 335px) {
        .cardBottomSub{
            height: 60%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            padding: 1rem;
            border-radius: 7.926px;
            background: #F8F9FB;
        } 
        .cardBottomSub span:nth-child(1){
            color: #FD5942;
            font-family: Inter;
            font-size: 12.681px;
            font-style: normal;
            font-weight: 600;
            padding-left: 0;
            white-space: nowrap;
        }
        .cardTopLeft span:nth-child(1){
            color: #364153;
            font-family: Inter;
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 600;
            white-space: nowrap;
        }
        
    }

    @media only screen and (max-width: 295px) {
        .cardBottomSub{
            height: 60%;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            gap: 1rem;
            padding: 1rem;
            border-radius: 7.926px;
            background: #F8F9FB;
        } 
    }

