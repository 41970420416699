.contactUsContainer{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #fff;
}

.imgContainer{
    width: 60rem;
    height: 100%;
    overflow: hidden;
 }

.imgContainer img {
        width: 100%;
        height: 100%;
        object-fit: cover; 

}

.leftCont{
    width: 100%;
    height: 100%;
    padding: 2rem;
    overflow-y: scroll;
    background-color: #fff;
   }
   
   .leftContSub{
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:start; 
    gap: 2.5rem;
    overflow-y: scroll;
    background-color: #fff;
   }

   .leftContSub img{
    height: 6rem;
    align-self: baseline;
    cursor: pointer;
   }

   .popup {
    background-color:#fff;
    width: 70%;
    height: fit-content;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
    border-radius: 5px;
    padding: 0rem 3rem;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: baseline;
    }

    .heading{
    color: rgba(16, 24, 52, 0.70);
    font-family: Inter;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    align-self: baseline;

    }
  
    .popup span:nth-child(2) {
      color: #101834;
      text-align: center;
      font-family: Inter;
      font-size: 2.3rem;
      font-style: normal;
      font-weight: 600;
      /* line-height: 48px; */
    }
  
    .popup span:nth-child(4) {
      color: rgba(0, 0, 0, 0.75);
      text-align: center;
      font-family: Inter;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 1rem;
    }
  
    .popup div:nth-child(5){
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
    }
  
    .popup div span{
      color: rgba(0, 0, 0, 0.75);
      text-align: center;
      font-family: Inter;
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin-top: 1rem;
    }
  
    .popup div a{
      color: #36B14D;
      text-align: center;
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-decoration: none;
      padding-top: 0.9rem;
    }
    
    .loginCont {
      background-color: rgb(82, 82, 82);
      position: absolute;
      z-index: 10;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
    
    .popup h3 {
      text-align: center;
      margin-bottom: 2rem;
    }
    
    /* Styles for the login form inside the popup */
    .popup form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.3rem;
      border-radius: 4px;
      height: fit-content;
      width: 100%;
      padding: 1.5rem;
      background: #ECECEC;
      /* z-index: 9999; */
    }
  
    .popup form a{
      color: #36B14D;
      text-align: center;
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      text-decoration: none;
      margin-top: 0.5rem;
    }
    
    .popup input[type="text"],
    .popup input[type="password"] {
      color: black;
      /* z-index: 9999; */
      height: 3rem;
      font-size: 14px;
      padding-left: 5px;
      border-radius: 4px;
      border: 1px solid #BCBDC2;
      background: #FFF;
    }
    
    .popup input[type="text"]::placeholder,
    .popup input[type="password"]::placeholder {
      font-size: 14px;
    }
    
    .popup input[type="submit"] {
      border-radius: 4px;
      border: 1px solid #36B14D;
      background: #36B14D;
      color: #fff;
      cursor: pointer;
      color: #FFF;
      width: 100%;
      height: 3rem;
      text-align: center;
      font-family: Inter;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 600;
      z-index: 9999;
    }
    
    .popup input[type="submit"]:hover {
      background-color:#42dc5e;
      border: 1px solid #42dc5e;
    }
    
    .errMsg {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }

    .errMsg label{
        font-size: 1.4rem;
        margin-bottom:'0.5rem';
    }
    
    .infoCont{
        display: flex;
        flex-direction: column;
        width: fit-content;
        gap: 1rem;
        margin-top: 3rem;
    }

    .info{
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;
    }

    .info img{
        width: 1.8rem;
        height: 1.8rem;
        align-self: center !important;
    }

    .info span{
        color: rgba(0, 0, 0, 0.60) !important;
        font-family: Inter ! ;
        font-size: 1.3rem !important;
        font-style: normal !important;
        font-weight: 500 !important;
        margin-top: 0 !important;
        text-align: left !important;
    }

@media only screen and (max-width: 1031px) {
    .imgnBtnContainer div div{
        max-width:18rem;
        height: 18rem;
        display: flex;
        flex-direction: column;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
    }

    .bottomContainer div{
        width:30rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 961px) {
    .imgnBtnContainer div div{
        max-width:16rem;
        height: 18rem;
        display: flex;
        flex-direction: column;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
    }
    .bottomContainer div{
        width:25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 918px) {

    .bottomContainer div{
        width:20rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 889px) {
    .imgnBtnContainer div div{
        max-width:14rem;
        height: 18rem;
        display: flex;
        flex-direction: column;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
    }
    .popup {
        background-color:#fff;
        width: 90%;
        height: fit-content;
        /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
        border-radius: 5px;
        padding: 0rem 3rem;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        align-items: baseline;
        }
    .popup form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.3rem;
        border-radius: 4px;
        height: fit-content;
        width: 100%;
        padding: 1.5rem;
        background: #ECECEC;
        z-index: 9999;
      }
}

@media only screen and (max-width: 855px) {

    .bottomContainer div{
        width:15rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 816px) {
    .imgnBtnContainer div{
        width:100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        flex-wrap:wrap ;
        row-gap: 2rem;
        justify-content: space-around;
        /* background-color: beige; */
    }
    
}

@media only screen and (max-width: 790px) {
    .bottomContainerResponsive{
        width: 100%;
        height: 100%;
        padding: 1rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:2rem;
        justify-content: space-between;
        /* background-color: blueviolet; */
    }

    .bottomContainerResponsive div:nth-child(1){
        width:25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .bottomContainerResponsive div:nth-child(2){
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: end;
        justify-content: space-between;
    }
    
}

@media only screen and (max-width: 745px) {

.popup {
    background-color:#fff;
    width: 98%;
    height: fit-content;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
    border-radius: 5px;
    padding: 0rem 1rem;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items:baseline;
    }
.popup form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    border-radius: 4px;
    height: fit-content;
    width: 100%;
    padding: 1.5rem;
    background: #ECECEC;
    z-index: 9999;
  }

}

@media only screen and (max-width: 710px) {
    .infoCont{
        display: flex;
        flex-direction: column;
        width: fit-content;
        gap: 1rem;
        margin-top: 3rem;
    }
}

@media only screen and (max-width: 685px) {

    .bottomContainerResponsive div:nth-child(1){
        width:20rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

@media only screen and (max-width: 610px) {

    .bottomContainerResponsive div:nth-child(1){
        width:15rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

@media only screen and (max-width: 634px) {
    .imgnBtnContainer div div{
        max-width:12rem;
        height: 16rem;
        display: flex;
        flex-direction:row;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
    }
}

@media only screen and (max-width: 590px) {
    .heading{
        color: rgba(16, 24, 52, 0.70);
        font-family: Inter;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        align-self: baseline;
        }
        .popup {
            background-color:#fff;
            width: 98%;
            height: fit-content;
            /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
            border-radius: 5px;
            padding: 0rem 0rem;
            display: flex;
            margin: 0 auto;
            flex-direction: column;
            align-items: baseline;
            }
}

@media only screen and (max-width: 579px) {
    .imgnBtnContainer div div{
        min-width:80%;
        height: 16rem;
        display: flex;
        flex-direction:row;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
    }
    .leftCont{
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        overflow-y: scroll;
        /* background-color: #fff; */
       }
}

@media only screen and (max-width: 550px) {
    .leftCont{
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        overflow-y: scroll;
        /* background-color: #fff; */
       }

       /* .leftContSub div:nth-child(1) span{
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 1.7rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    } */
}

@media only screen and (max-width: 500px) {
    .contactUsContainer{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem;
        /* background-color: yellow; */
    }
    .imgContainer{
        width: 100%;
        height: 32rem;
        overflow: hidden;
     }
     .imgnBtnContainer div div{
        min-width:16rem;
        height: 16rem;
        display: flex;
        flex-direction:row;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
    }
    .bottomContainerResponsive div:nth-child(1){
        width:25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .popup {
        background-color:#fff;
        width: 85%;
        height: fit-content;
        /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
        border-radius: 5px;
        padding: 0rem 1rem;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        align-items: baseline;
        }
        .popup form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.3rem;
            border-radius: 4px;
            height: fit-content;
            width: 90%;
            padding: 1.5rem;
            background: #ECECEC;
            z-index: 9999;
          }
}

@media only screen and (max-width: 331px) {
    .imgnBtnContainer div div{
        min-width:80%;
        height: 17rem;
        display: flex;
        flex-direction:row;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
    }

    .bottomContainerResponsive div:nth-child(1){
        width:18rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 275px) {
    .bottomContainerResponsive div:nth-child(1){
        width:15rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

