.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    overflow-y: scroll;
  }

  .modalPreview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    z-index: -10;
  }

  /* .modalPrint {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    z-index: -10;
  } */
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 55%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
  }

  .modalContentSub{
    width: 100%;
    padding: 2rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    /* border: 1.3px solid black; */
  }
  .headerCont{
    width: 100%;
    height: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-bottom: none;
  }

  .headerCont span{
    font-size: 2.5rem;
    font-weight: 700;
    color: #36B14D;
    margin-bottom: 0.5rem;
  }
  .headerCont img{
    align-self: baseline;
    height: 4rem !important;
    width: 13rem !important;
  }

  .detailsCont{
    width: 100%;
    height: 12rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1.3px solid #000;
    border-bottom: none;
    /* border-left: none;
    border-right: none; */
    /* background-color: yellow; */
  }

  .addressCont{
    width: 50%;
    height: 100%;
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .addressContTop{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    /* background-color: #36B14D; */
  }

  .addressContBottom{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    /* background-color:orchid; */
  }

  .addressContBottomLeft{
    display: flex;
    flex-direction: column;
    font-size: 0.95rem;
    gap:0.52rem;
    align-items: end;
    white-space: nowrap;
  }

  .addressContBottomRight{
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 500;
    gap:0.4rem;
    white-space: nowrap;
  }

  .infoCont{
    width: 50%;
    height: 100%;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .infoContTop{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    /* background-color:orchid; */
  }

  .infoContBottom{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    /* background-color:aquamarine; */
  }


  .heading {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000;
  }
  
  .headerOrgAddress {
    font-size: 1rem;
    font-weight: 500;
    color: #000;
  }

  .infoCont{
    width: 40%;
    height: 100%;
  }

  .tableCont {
    width: 100% !important;
    height: fit-content;
    /* background-color: aqua; */
    margin: 0 auto;
    /* margin-top: 2rem; */
    border: 1.3px solid #000;
    border-top: none;
  }

  .tableCont table{
    border-spacing: 0;
  }
  
  .tableCont table thead th {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
    text-align: left;
    font-weight: 600;
    font-size: 10px;
    color: #000;
    background-color: transparent;
    border-spacing: 0;
    border: 1.2px solid #000;
    border-bottom: none;
    border-left: none;
  }

  .tableCont table thead th:last-child {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
    text-align: left;
    font-weight: 600;
    font-size: 10px;
    color: #000;
    background-color: transparent;
    border-spacing: 0;
    border: 1.2px solid #000;
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  .firstRow td{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-left: 4px !important;
    text-align: center !important;
    font-size: 11px !important;
    font-weight: 600 !important;
    color: #fff !important;
    border: 1.2px solid #000 !important;
    background-color: #36B14D;
    border-bottom: none !important;
    border-left: none !important;
  }

  .firstRow td:last-child{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-left: 4px !important;
    text-align: center !important;
    font-size: 11px !important;
    font-weight: 600 !important;
    color: #fff !important;
    border: 1.2px solid #000 !important;
    background-color: #36B14D;
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
  }
  
  .tableCont table tbody tr td {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
    text-align: left;
    font-size: 10px;
    color: #000;
    border: 1.2px solid #000;
    border-bottom: none;
    border-left:none;
  }

  .tableCont table tbody tr:last-child td {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
    text-align: left;
    font-size: 10px;
    color: #000;
    border-bottom: 1.2px solid #000;
  }

  .tableCont table tbody tr td:last-child {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
    text-align: left;
    font-size: 10px;
    color: #000;
    border-right: none;
  }

  .descDataCont{
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: 600;
    font-size: 10px;
    color: #000;
  }

  .descDataCont span{
    font-size: 10px;
    font-weight: normal;
    color: #000;
  }

  .numberCont{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .tableCont table tfoot tr td {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 4px;
    text-align: right;
    font-weight: 600;
    font-size: 10px;
    color: #fff;
    border: 1.2px solid #000;
    border-top: none;
    border-right: none;
    border-bottom: none;
  }

  .footerCont{
    width: 100%;
    height: fit-content;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    border: 1.3px solid #000;
    border-top: none;
    /* background-color: yellow; */
  }

  .footerContspan{
    font-size: 0.9rem;
    font-weight: 600;
    color: #333;
    text-decoration: underline;
  }

  .footerContBottom{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    /* background-color: #e2e2e2; */
  }


  .footerContBottomSub{
    display: flex;
    flex-direction: row;
    font-size: 0.7rem;
    gap:0.3rem;
  }

  .footerContBottomRight{
    display: flex;
    flex-direction: column;
    font-size: 0.7rem;
    gap:0.3rem;
  }

  .printBtn{
    width: 55%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }

  .printBtn button{
    width: 7rem;
    height: 3rem;
    background-color: #36B14D;
    border: 1px solid #36B14D;
    border-radius: 3px;
    color: #FFF;
    font-family: Raleway;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
  }
  
  @media only screen and (max-width: 920px) {
    .modalContent {
      background: white;
      padding: 20px;
      border-radius: 8px;
      width: 70%;
      height: fit-content;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: scroll;
    }
  }

  @media only screen and (max-width: 778px) {
    .modalContent {
      background: white;
      padding: 20px;
      border-radius: 8px;
      width: 80%;
      height: fit-content;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: scroll;
    }
  }

  @media only screen and (max-width: 650px) {
    .modalContent {
      background: white;
      padding: 10px;
      border-radius: 8px;
      width: 85%;
      height: fit-content;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: scroll;
    }
    .modalContentSub{
      width: 100%;
      padding: 1rem;
      height: fit-content;
      display: flex;
      flex-direction: column;
      /* border: 1.3px solid black; */
    }
  }

  @media only screen and (max-width: 550px) {
    .modalContent {
      background: white;
      padding: 10px;
      border-radius: 8px;
      width: 90%;
      height: fit-content;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      overflow: scroll;
    }
    .modalContentSub{
      width: fit-content;
      padding: 1rem;
      height: fit-content;
      display: flex;
      flex-direction: column;
      /* border: 1.3px solid black; */
    }
    .headerCont{
      width: 50rem;
      height: 10rem;
      /* padding: 0 1rem; */
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      /* border: 1.3px solid black; */
      border-bottom: none;
      /* background-color: red; */
    }
    .detailsCont{
      width: 50rem;
      height: 12rem;
      padding: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap:1rem;
      border: 1.3px solid #000;
      border-bottom: none;
      /* border-left: none;
      border-right: none; */
      /* background-color: yellow; */
    }
    .tableCont {
      width: 50rem !important;
      height: fit-content;
      /* background-color: aqua; */
      margin: 0 auto;
      /* margin-top: 2rem; */
      border: 1.3px solid #000;
      border-top: none;
    }
    .footerCont{
      width: 50rem;
      height: fit-content;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      gap:0.5rem;
      border: 1.3px solid #000;
      border-top: none;
      /* background-color: yellow; */
    }
  }

