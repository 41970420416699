.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  }

.popup {
    background-color:#fff;
    width: 315px;
    height: fit-content;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
    border-radius: 5px;
    padding: 2rem 3rem;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
  }

  .popup img{
    width: 10rem;
    height: 4rem;
    margin-bottom: 2rem;
  }

  .popup span:nth-child(2) {
    color: #101834;
    text-align: center;
    font-family: Inter;
    font-size: 2.3rem;
    font-style: normal;
    font-weight: 600;
    /* line-height: 48px; */
  }

  .popup span:nth-child(3) {
    color: rgba(0, 0, 0, 0.75);
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 1rem;
  }

  .popup span:nth-child(5){
    color: rgba(0, 0, 0, 0.75);
    text-align: center;
    font-family: Inter;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-top: 1rem;
  }

  .popup a{
    color: #36B14D;
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
  }
  
  .loginCont {
    background-color: rgb(82, 82, 82);
    position: absolute;
    z-index: 10;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  
  .popup h3 {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  /* Styles for the login form inside the popup */
  .popup form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    border-radius: 4px;
    height: fit-content;
    width: 25rem;
    padding: 1.5rem;
    background: #ECECEC;
    z-index: 9999;
  }

  .popup form a{
    color: #36B14D;
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-decoration: none;
    margin-top: 0.5rem;
  }
  
  .popup input[type="text"],
  .popup input[type="password"] {
    color: black;
    z-index: 9999;
    height: 3rem;
    font-size: 14px;
    padding-left: 5px;
    border-radius: 4px;
    border: 1px solid #BCBDC2;
    background: #FFF;
  }
  
  .popup input[type="text"]::placeholder,
  .popup input[type="password"]::placeholder {
    font-size: 14px;
  }
  
  .popup input[type="submit"] {
    border-radius: 4px;
    border: 1px solid #36B14D;
    background: #36B14D;
    color: #fff;
    cursor: pointer;
    color: #FFF;
    width: 100%;
    height: 3rem;
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    z-index: 9999;
  }
  
  .popup input[type="submit"]:hover {
    background-color:#42dc5e;
    border: 1px solid #42dc5e;
  }
  
  .errMsg {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .errMsg label{
    color: #101834;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 0.3rem;
  }

  .errMsgSpan {
    color:red !important;
    text-align: left !important;
    font-family: Inter;
    font-size: 0.9rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 0px !important;
    margin-bottom: 0 !important;
    margin-top: 1rem;
  }

  .loginErrdiv{
    margin-top: 0.5rem;
    background-color:transparent;
    padding: 0.3rem;
  }

  .loginErr {
    color:red !important;
    text-align: left !important;
    font-family: Inter;
    font-size: 1.2rem !important;
    font-style: normal;
    font-weight: 500;
    line-height: 0px !important;
    margin-bottom: 0 !important;
    margin-top: 1rem;
  }
  
  
  @media only screen and (max-width: 340px) {
    .popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color:#fff;
        width: 25rem;
        /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
        border-radius: 5px;
        padding: 2rem 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
      }

      .popup form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.3rem;
        border-radius: 4px;
        height: 23rem;
        width: 20rem;
        padding: 1.5rem;
        background: #ECECEC;
        z-index: 9999;
      }
  }
  
  @media only screen and (max-width: 290px) {
    .popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color:#fff;
        width: 23rem;
        /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
        border-radius: 5px;
        padding: 2rem 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
      }

      .popup form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.3rem;
        border-radius: 4px;
        height: 23rem;
        width: 16rem;
        padding: 1.5rem;
        background: #ECECEC;
        z-index: 9999;
      }
  }
  
  @media only screen and (max-width: 255px) {
    .popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color:#fff;
        width: 20rem;
        /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
        border-radius: 5px;
        padding: 2rem 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
      }

      .popup form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.3rem;
        border-radius: 4px;
        height: 23rem;
        width: 15rem;
        padding: 1.5rem;
        background: #ECECEC;
        z-index: 9999;
      }
      .popup span:nth-child(3) {
        color: rgba(0, 0, 0, 0.75);
        text-align: center;
        font-family: Inter;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 1rem;
      }
  }
  
