.loaderPageContainer{
    height: 100%;
    width: 100%;
    padding: 2rem 2rem;
    background-image: url('../images/loaderBg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
}

@media only screen and (max-width: 500px) {
    .loaderPageContainer{
        height: 100vh;
        width: 100%;
        padding: 2rem 2rem;
        background-image: url('../images/loaderBg.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    
}

.wrapper{
    position:relative;
    /*background:#333;*/
    height:100%;
}

.profileMainLoader{
    left: 50% !important;
    margin-left:-100px;
    position: fixed !important;
    top: 50% !important;
    margin-top: -100px;
    width: 45px;
    z-index: 9000 !important;
}

.profileMainLoader .loader {
  position: relative;
  margin: 0px auto;
  width: 200px;
  height:200px;
}
.profileMainLoader .loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circularLoader {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
          transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}

.loaderPath {
  stroke-dasharray: 150,200;
  stroke-dashoffset: -10;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: #70c542;
  }
  40% {
    stroke: #70c542;
  }
  66% {
    stroke: #70c542;
  }
  80%, 90% {
    stroke: #70c542;
  }
}
@keyframes color {
  0% {
    stroke: #70c542;
  }
  40% {
    stroke: #70c542;
  }
  66% {
    stroke: #70c542;
  }
  80%, 90% {
    stroke: #70c542;
  }
}
