.quotationPageCont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    /* padding-bottom: 2rem; */
    overflow-y: scroll;
    background-color: #F8F9FB;
}

.headingCont{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    gap:2rem;
    justify-content: space-between;
}

.headingContLeft{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* background-color: yellow; */
}

.headingCont span:nth-child(1){
    color: #364153;
    font-family: Raleway;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.card{
    height: 14.5rem;
    max-width: 32rem;
    border-radius: 8px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.cardTop{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: aqua; */
}
.cardTopLeft{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
}

.cardTopLeft span:nth-child(1){
    color: #364153;
    font-family: Inter;
    font-size: 3.1rem;
    font-style: normal;
    font-weight: 600;
    white-space: nowrap;
}

.cardTopLeft span:nth-child(2){
    color: #364153;
    font-family: Raleway;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    white-space: nowrap;
}

.cardTop img{
    width: 4rem;
    height: 4rem;
    /* object-fit: cover; */
}

.cardBottom{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.cardBottomSub{
    height: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    border-radius: 7.926px;
    background: #F8F9FB;
}

.cardBottomSub span:nth-child(1){
    color: #FD5942;
    font-family: Inter;
    font-size: 12.681px;
    font-style: normal;
    font-weight: 600;
    padding-left: 2rem;
    white-space: nowrap;
}


.cardBottomSub span:nth-child(2){
    color: #6B6B6B;
    font-family: Inter;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
}

.searchnFiltCont{
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap:1rem
}

.searchContainer {
    display: flex;
    align-items: center;
  }

  .searchBox {
    position: relative;
  }

  .searchBox input[type="text"] {
    padding: 10px 30px 10px 40px; 
    border: none;
    border-radius: 7px;
    outline: none;
    width: 18rem; /* Set the desired width */
    color: #747474;
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
  }

  .searchBox img {
    position: absolute;
    left: 10px; 
    top: 50%;
    transform: translateY(-50%);
    width: 20px; 
    height: 20px; 
  }

  .filterContainer {
    display: flex;
    align-items: center;
  }

  .filterBox {
    position: relative;
    display: flex;
    flex-direction: row;
    gap:1rem;
  }

  .filterBox select {
    padding: 10px 30px 10px 2px; 
    border: none;
    border-radius: 7px;
    outline: none;
    width: 14.5rem; /* Set the desired width */
    color: #747474;
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
  }

  .filterBox img {
    position: absolute;
    left: 10px; 
    top: 50%;
    transform: translateY(-50%);
    width: 20px; 
    height: 20px; 
  }

  .headingContRight{
    width: fit-content;
    height: fit-content;
}

.headingContRightSub{
    width: 23rem;
    height: 23.5rem;
    background-color: #fff;
    position: relative;
}

.headingContRightSubOverlay{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: -1;
} 

.headingContRightSub:hover .headingContRightSubOverlay{
  z-index: 10;
}

.headingContRightSubOverlay:hover{
  z-index: 10;
}

.bottomCont{
    width: 100%;
    height: fit-content;
    min-height: 20rem;
    overflow-y: scroll;
}

.bottomContTable{
    width: 100%;
    height: fit-content;
    overflow-y: scroll;
}

.paginationContainer {
  display: flex;
  justify-content: end;
  /* margin-top: 10px; */
  align-items: center;
}

.currentPageCont{
  border: 1px solid #000;
  height: fit-content;
  min-height: 2.8rem;
  width: fit-content;
  min-width: 2rem;
  padding:0px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.currentPageCont span{
  font-size: 1.4rem !important;
  font-weight: 600 !important;
}

.paginationContainer button {
  width: 7rem;
  background-color: #4caf50;
  border: none;
  color: white;
  padding:5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
}

.viewBtn {
  width: fit-content;
  background-color: #4caf50;
  border: none;
  color: white;
  padding:5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
}

.paginationContainer button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

@media only screen and (max-width: 957px) {
    .searchBox input[type="text"] {
        padding: 10px 30px 10px 40px; 
        border: none;
        border-radius: 7px;
        outline: none;
        width: 18rem; /* Set the desired width */
        color: #747474;
        font-family: Inter;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
      }
}

@media only screen and (max-width: 925px) {
  .searchBox input[type="text"] {
      padding: 10px 30px 10px 40px; 
      border: none;
      border-radius: 7px;
      outline: none;
      width: 16rem; /* Set the desired width */
      color: #747474;
      font-family: Inter;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
    }
}

@media only screen and (max-width: 900px) {
  .headingContLeft{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    /* background-color: yellow; */
}
.headingContRight{
  width: fit-content;
  height: fit-content;
  align-self: center;
}
  .searchnFiltCont{
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:1.5rem
}
.searchBox input[type="text"] {
  padding: 10px 30px 10px 40px; 
  border: none;
  border-radius: 7px;
  outline: none;
  width: 32rem; /* Set the desired width */
  color: #747474;
  font-family: Inter;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
}
.filterBox select {
  padding: 10px 30px 10px 2px; 
  border: none;
  border-radius: 7px;
  outline: none;
  width: 15.5rem; /* Set the desired width */
  color: #747474;
  font-family: Inter;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
}
}



@media only screen and (max-width: 767px) {
  .bottomCont{
    width: 100%;
    height: fit-content;
    overflow-y: scroll;
    overflow-x: scroll;
}
  .bottomContTable{
    width: 70rem;
    height: fit-content;
    overflow-y: scroll;
}
}

@media only screen and (max-width: 742px) {
  .headingCont{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    gap:2rem;
    justify-content: space-between;
    align-items: center;
}
  .searchnFiltCont{
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:1rem
}
.searchContainer {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .searchBox {
    width:100%;
    position: relative;
  }
.searchBox input[type="text"] {
    padding: 10px 30px 10px 40px; 
    border: none;
    border-radius: 7px;
    outline: none;
    width: 100%;
    color: #747474;
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
  }

  
  .filterContainer {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .filterBox {
    width:100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:1rem;
  }
  .filterBox select {
    padding: 10px 30px 10px 2px; 
    border: none;
    border-radius: 7px;
    outline: none;
    width: 13rem; /* Set the desired width */
    color: #747474;
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
  }
}

@media only screen and (max-width: 690px) {
  .filterBox select {
    padding: 10px 30px 10px 2px; 
    border: none;
    border-radius: 7px;
    outline: none;
    width: 10rem; /* Set the desired width */
    color: #747474;
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
  }

}

@media only screen and (max-width: 662px) {
  .card{
      height: fit-content;
      max-width: 32rem;
      border-radius: 8px;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      gap:1rem;
      background-color: #fff;
  }
  .cardTop{
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: baseline;
      /* background-color: aqua; */
  }
  .cardBottomSub{
      height: 60%;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      gap: 2rem;
      padding: 1rem;
      border-radius: 7.926px;
      background: #F8F9FB;
  }
  .cardBottomSub span:nth-child(1){
      color: #FD5942;
      font-family: Inter;
      font-size: 12.681px;
      font-style: normal;
      font-weight: 600;
      padding-left: 0;
      white-space: nowrap;
  }
}

@media only screen and (max-width: 642px) {
  .headingCont{
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction:column;
      padding-top: 1rem;
      gap:2rem;
      justify-content: space-between;
      align-items: center;
  }
  .card{
      height: 14.5rem;
      max-width: 100%;
      border-radius: 8px;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      background-color: #fff;
  }
  
  .cardTop{
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      /* background-color: aqua; */
  }

  .cardBottomSub{
      height: 60%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2rem;
      padding: 1rem;
      border-radius: 7.926px;
      background: #F8F9FB;
  }
  
  .cardBottomSub span:nth-child(1){
      color: #FD5942;
      font-family: Inter;
      font-size: 12.681px;
      font-style: normal;
      font-weight: 600;
      padding-left: 2rem;
      white-space: nowrap;
  }

  .headingContLeft{
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      /* background-color: yellow; */
  }
  .filterBox select {
    padding: 10px 30px 10px 2px; 
    border: none;
    border-radius: 7px;
    outline: none;
    width: 20rem; /* Set the desired width */
    color: #747474;
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
  }

  .quotationPageCont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    /* padding-bottom: 2rem; */
    overflow-y: scroll;
    background-color: #F8F9FB;
}

}

@media only screen and (max-width: 583px) {
  .filterBox select {
    padding: 10px 30px 10px 2px; 
    border: none;
    border-radius: 7px;
    outline: none;
    width: 18rem; /* Set the desired width */
    color: #747474;
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
  }
}


@media only screen and (max-width: 546px) {
  .filterBox select {
    padding: 10px 30px 10px 2px; 
    border: none;
    border-radius: 7px;
    outline: none;
    width: 16rem; /* Set the desired width */
    color: #747474;
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
  }
}


@media only screen and (max-width: 500px) {
.quotationPageCont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    /* padding-bottom: 2rem; */
    overflow-y: scroll;
    background-color: #F8F9FB;
}
.headingContLeft{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* background-color: yellow; */
}
.searchnFiltCont{
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:1.5rem
}
.filterBox select {
  padding: 10px 30px 10px 2px; 
  border: none;
  border-radius: 7px;
  outline: none;
  width: 20rem; /* Set the desired width */
  color: #747474;
  font-family: Inter;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
}
}

@media only screen and (max-width: 439px) {
  .filterBox select {
    padding: 10px 30px 10px 2px; 
    border: none;
    border-radius: 7px;
    outline: none;
    width: 18rem; /* Set the desired width */
    color: #747474;
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
  }
}

@media only screen and (max-width: 400px) {
  .filterBox select {
    padding: 10px 30px 10px 2px; 
    border: none;
    border-radius: 7px;
    outline: none;
    width: 16rem; /* Set the desired width */
    color: #747474;
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
  }
}

@media only screen and (max-width: 356px) {
  .filterBox select {
    padding: 10px 30px 10px 2px; 
    border: none;
    border-radius: 7px;
    outline: none;
    width: 14rem; /* Set the desired width */
    color: #747474;
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
  }
}

@media only screen and (max-width: 316px) {
  .filterContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .filterBox {
    width:100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:1.5rem;
  }
  .filterBox select {
    padding: 10px 30px 10px 2px; 
    border: none;
    border-radius: 7px;
    outline: none;
    width: 100%; /* Set the desired width */
    color: #747474;
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
  }
}

@media only screen and (max-width: 266px) {

    .card{
        height: fit-content;
        max-width: 100%;
        border-radius: 8px;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        background-color: #fff;
    }

    .cardTopLeft span:nth-child(2){
        color: #364153;
        font-family: Inter;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 600;
        white-space:pre-wrap !important;
    }
    
    .cardBottomSub span:nth-child(1){
        color: #FD5942;
        font-family: Inter;
        font-size: 12.681px;
        font-style: normal;
        font-weight: 600;
        padding-left: 0;
        white-space: nowrap;
    }
}