.loaderPageContainer{
    height: 100%;
    width: 100%;
    padding: 2rem 2rem;
    background-color: #FFF;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
  }

  .modalContent {
    background: white;
    padding: 30px;
    border-radius: 8px;
    width: fit-content;
    max-width: 25rem;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    overflow-y: scroll;
  }

  .circle {
    width: 30px;
    height: 30px;
    background-color:#36B14D; /* You can set the color you prefer */
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .modalContent span{
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    white-space: pre-wrap;
  }

  .inputCont{
    display: flex;
    flex-direction: column;
  }

  .inputCont span{
    line-height: 1.2rem !important;
  }

  .modalContent input{
    height: 30px;
    border-radius: 5px;
    border: 1px solid #36B14D;
    background: #FFF;
    font-size: 1.1rem;
    font-weight: 500;
    padding-left: 4px;
  }

  .modalContent button{
    width: 16rem;
    height: 2.8rem;
    background-color: #36B14D;
    border: 1px solid #36B14D ;
    border-radius: 3px;
    color: #FFF;
    font-family: Raleway;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
}

.modalContent button:hover{
    background-color:#42dc5e;
    border: 1px solid #42dc5e;
}

.errMsgSpan {
    color:red !important;
    text-align: left !important;
    font-family: Inter;
    font-size: 0.9rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 0px !important;
    margin-bottom: 0 !important;
    margin-top: 1rem;
  }

  .reqRess{
    width: fit-content;
  }


@media only screen and (max-width: 500px) {
    .loaderPageContainer{
        height: 100vh;
        width: 100%;
        padding: 2rem 2rem;
        background-color: #FFF;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    
}