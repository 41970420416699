.priorityPageContainer{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #fff;
}

.imgContainer{
    width: 60rem;
    height: 100%;
    overflow: hidden;
 }

.imgContainer img {
        width: 100%;
        height: 100%;
        object-fit: cover; 

}

.leftCont{
    width: 100%;
    height: 100%;
    padding: 3rem;
    overflow-y: scroll;
    background-color: #fff;
   }
   
   .leftContSub{
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:start; 
    gap: 4rem;
    /* background-color: antiquewhite; */
   }

   .leftContSub div:nth-child(1) span{
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.imgnBtnContainer{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 3rem;
    /* background-color: aqua; */
}

.imgnBtnContainer div{
    width:100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap:wrap ;
    justify-content: space-around;
    gap:3rem;
    /* background-color: beige; */
}

.imgnBtnContainer div div{
    max-width:24rem;
    height: 28rem;
    display: flex;
    flex-direction: column;
    gap:1rem;
    align-items: center;
    padding: 0 1rem;
    /* background-color:white; */
}

.imgnBtnContainer div img{
width: 100%;
height: 75%;
cursor: pointer;
object-fit: cover;
}

.imgnBtnContainer div button{
    width: 100% !important;
}

.bottomContainer{
    width: 100%;
    height: 100%;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    margin-top: 1rem;
    /* background-color: blueviolet; */
}

.bottomContainer div{
    width:35rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: yellowgreen; */
}

@media only screen and (max-width: 1031px) {
    .imgnBtnContainer div div{
        max-width:20rem;
        height: 28rem;
        display: flex;
        flex-direction: column;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
    }

    .bottomContainer div{
        width:30rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 961px) {
    .imgnBtnContainer div div{
        max-width:20rem;
        height: 28rem;
        display: flex;
        flex-direction: column;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
    }
    .bottomContainer div{
        width:25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 918px) {

    .bottomContainer div{
        width:20rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 889px) {
    .imgnBtnContainer div div{
        max-width:20rem;
        height:28rem;
        display: flex;
        flex-direction: column;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
    }
}

@media only screen and (max-width: 855px) {

    .bottomContainer div{
        width:15rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 816px) {
    .imgnBtnContainer div{
        width:100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        flex-wrap:wrap ;
        row-gap: 2rem;
        justify-content: space-around;
        /* background-color: beige; */
    }
    
}

@media only screen and (max-width: 790px) {
    .bottomContainerResponsive{
        width: 100%;
        height: 100%;
        padding: 1rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:2rem;
        justify-content: space-between;
        /* background-color: blueviolet; */
    }

    .bottomContainerResponsive div:nth-child(1){
        width:25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .bottomContainerResponsive div:nth-child(2){
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: end;
        justify-content: space-between;
    }
    
}

@media only screen and (max-width: 685px) {

    .bottomContainerResponsive div:nth-child(1){
        width:20rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

@media only screen and (max-width: 610px) {

    .bottomContainerResponsive div:nth-child(1){
        width:15rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

@media only screen and (max-width: 634px) {
    .imgnBtnContainer div div{
        max-width:20rem;
        height: 28rem;
        display: flex;
        flex-direction:row;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
    }
}

@media only screen and (max-width: 579px) {
    .imgnBtnContainer div div{
        min-width:80%;
        height: 28rem;
        display: flex;
        flex-direction:row;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
    }
    .leftCont{
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        overflow-y: scroll;
        /* background-color: #fff; */
       }
}

@media only screen and (max-width: 550px) {
    .leftCont{
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        overflow-y: scroll;
        /* background-color: #fff; */
       }

       .leftContSub div:nth-child(1) span{
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 1.7rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

@media only screen and (max-width: 500px) {
    .leftContSub div:nth-child(1) span{
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .priorityPageContainer{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem;
        /* background-color: yellow; */
    }
    .imgContainer{
        width: 100%;
        height: 32rem;
        overflow: hidden;
     }
     .imgnBtnContainer div div{
        min-width:20rem;
        height: 28rem;
        display: flex;
        flex-direction:row;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
    }
    .bottomContainerResponsive div:nth-child(1){
        width:25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 331px) {
    .imgnBtnContainer div div{
        min-width:80%;
        height: 28rem;
        display: flex;
        flex-direction:row;
        gap:1rem;
        align-items: center;
        padding: 0 1rem;
    }

    .bottomContainerResponsive div:nth-child(1){
        width:18rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 275px) {
    .bottomContainerResponsive div:nth-child(1){
        width:15rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

